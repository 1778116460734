import React from 'react';
import { Grid, Typography, Fab } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Link from 'gatsby-link';
import DynamicServiceTextList from '../components/OurServicesPages/DynamicServiceTextList';
import DynamicServiceConceptList from '../components/OurServicesPages/DynamicServiceConceptList';
import imgProjectManagement from '../assets/project-management.png';
import { descriptions, concepts } from '../helpers/ProjectManagementHelper';
import { SEO } from '../shared/seo';
import Header from '../layout/Header/header';
import Footer from '../layout/Footer/footer';

import '../styles/ourServicesPagesStyles.css';

const overrides = {
  title: 'Project Management | Nearshore Code',
  description: 'We apply management solutions with an objective, professional approach allowing a faster time to market.',
  openGraph: {
    type: 'website',
    url: 'https://nearshorecode.com/project-management',
    title: 'Project Management | Nearshore Code',
    description: 'We apply management solutions with an objective, professional approach allowing a faster time to market.',
    image: 'https://nearshorecode.com/facebookimage.png',
  },
  twitter: {
    card: 'summary_large_image',
    url: 'https://nearshorecode.com/project-management',
    title: 'Project Management | Nearshore Code',
    description: 'We apply management solutions with an objective, professional approach allowing a faster time to market.',
    image: 'https://nearshorecode.com/twitterimage.png',
  },
};

const ProjectManagement = () => (
  <div>
    <SEO overrides={overrides} />
    <Header />
    <div className="modal-carousel-slide-item-container">
      <Grid container className="modal-carousel-slide-item">
        <Grid item xs={12}>
          <Typography variant="h4">Project Management</Typography>
        </Grid>
        <Grid container className="modal-carousel-slide-content">
          <Grid item xs={12} sm={6}>
            <div className="slide-content-img">
              <img src={imgProjectManagement} alt="Project Management" />
            </div>
            <DynamicServiceTextList descriptions={descriptions} />
          </Grid>
          <Grid item xs={12} sm={6} className="slide-content-concepts">
            <DynamicServiceConceptList concepts={concepts} />
          </Grid>
        </Grid>
      </Grid>
      <div className="slide-section-close">
        <Link to="/">
          <Fab className="slide-item-close" size="small" color="secondary" aria-label="close">
            <CloseIcon />
          </Fab>
        </Link>
      </div>
    </div>
    <Footer />
  </div>

);

export default React.memo(ProjectManagement);
